<template>
    <div class="container">
        <cardTitleCom cardTitle="签到规则">
            <template class="padd15" #cardContent>
                <div class="padd15">
                    <div class="x-f informBox marB10">
                        <i class="el-icon-warning marR10" style="color: #155bd3"></i>
                        <span>签到规则更新：不勾选"领取限制"，表明该会员每次满足设定的连续签到次数时，都可以领取奖励</span>
                    </div>
                    <div class="x-f-end">
                        <el-button size="mini" type="primary"@click="saveBtn">保存</el-button>
<!--                        <c-button class="whiteColor" test="保存" :bgColor="'#188ffe'" @click=""></c-button>-->
                    </div>
                    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
                        <el-tabs>
                            <el-tab-pane>活动信息</el-tab-pane>
                        </el-tabs>
                        <el-form-item class="marT20" label="启动状态">
                            <el-switch v-model="form.isEnableStatus" class="inputW"
                            ></el-switch>
                        </el-form-item>
                        <el-form-item label="活动标题">
                            <el-input v-model="form.activityTitle" class="inputW"></el-input>
                        </el-form-item>
                        <el-form-item label="活动内容">
                            <el-input type="textarea" class="inputW" :autosize="{ minRows: 3, maxRows: 4}"
                                      placeholder="请输入内容" v-model="form.activityContent"
                            >
                            </el-input>
                        </el-form-item>
                        <el-tabs>
                            <el-tab-pane>基本奖励</el-tab-pane>
                        </el-tabs>
                        <el-form-item class="marT20" :required="form.encourageType == 2">
                            <div class="x-f">
                                <el-radio v-model="form.encourageType" label="1">赠送积分</el-radio>
                                <el-input :disabled="form.encourageType == 2" v-model="form.baseRewardIntegral"
                                          @input="changeInput" class="inputW160 marL10"
                                ></el-input>
                                <span class="marL10">积分</span>
                            </div>
                        </el-form-item>
                        <el-form-item :required="form.encourageType == 1">
                            <div class="x-f">
                                <el-radio v-model="form.encourageType" label="2">随机赠送积分</el-radio>
                                <el-input :disabled="form.encourageType == 1" v-model="form.randomRewardIntegralStart"
                                          onkeyup="value=value.replace(/[^\d]/g,'')" class="inputW160 marL10"
                                ></el-input>
                                <span class="marL10 marR10">-</span>
                                <el-input :disabled="form.encourageType == 1" v-model="form.randomRewardIntegralEnd"
                                          onkeyup="value=value.replace(/[^\d]/g,'')" class="inputW160"
                                ></el-input>
                                <span class="marL10">积分</span>
                            </div>
                        </el-form-item>
                        <el-tabs>
                            <el-tab-pane>额外奖励</el-tab-pane>
                        </el-tabs>
                        <div v-for="(item, index) in templateItems" :key="index">
                            <el-form-item class="marT20" label="连续签到：">
                                <el-input v-model="item.continuationSignCount" class="inputW160 marL10"></el-input>
                                <span class="marL10">天</span>
                            </el-form-item>
                            <div class="x-bc">
                                <el-form-item class="marT20" :required="item.encourageType == 2">
                                    <el-radio v-model="item.encourageType" label="1">赠送积分</el-radio>
                                    <el-input :disabled="item.encourageType == 2" v-model="item.outsideRewardIntegral"
                                              onkeyup="value=value.replace(/[^\d]/g,'')" class="inputW160 marL10"
                                    ></el-input>
                                    <span class="marL10">积分</span>
                                </el-form-item>
                                <div class="y-start">
                                    <el-button type="text" @click="deleteItem(index)">删除当前</el-button>
                                    <span>#{{ index + 1 }}</span>
                                </div>
                            </div>
                            <el-form-item :required="item.encourageType == 1">
                                <div class="x-f">
                                    <el-radio v-model="item.encourageType" label="2">随机赠送积分</el-radio>
                                    <el-input :disabled="item.encourageType == 1"
                                              v-model="item.randomRewardIntegralStart"
                                              onkeyup="value=value.replace(/[^\d]/g,'')" class="inputW160 marL10"
                                    ></el-input>
                                    <span class="marL10 marR10">-</span>
                                    <el-input :disabled="item.encourageType == 1" v-model="item.randomRewardIntegralEnd"
                                              onkeyup="value=value.replace(/[^\d]/g,'')" class="inputW160"
                                    ></el-input>
                                    <span class="marL10">积分</span>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="text" v-model="item.isCoupon" @click="selectCoupon(index)">赠送优惠券
                                </el-button>
                                <div class="y-start">
                                    <span class="marL15">已选优惠券：</span>
                                    <div class="x-f" v-for="(it, i) in item.couponCaseItems" :key="it.couponCaseId">
                                        <el-tag v-if="item.couponCaseItems.length > 0" class="marL10" closable @close="deleteTag(i)"
                                        >{{ it.couponCaseName }}
                                        </el-tag>
                                        <el-input size="mini" class="marL10" style="width: 110px" v-model="it.couponQuantity" placeholder="输入赠送数量"></el-input>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="领取限制">
                                <el-checkbox v-model="item.isGetLimit">只能获取一次连续签到奖励</el-checkbox>
                                <el-tooltip class="item" effect="dark" :content="content" placement="top">
                                    <i class="el-icon-question marL10" style="color: #fd6633"></i>
                                </el-tooltip>
                            </el-form-item>
                        </div>
                    </el-form>
                    <el-divider></el-divider>
                    <div style="width: 100%" class="x-c">
                        <el-button type="text" icon="el-icon-plus" @click="addItem">新增一级额外奖励</el-button>
                    </div>
                </div>
            </template>
        </cardTitleCom>
        <SelectCoupon
            :OpenCoupon.sync="showCoupon"
            @getCoupons="getCoupons"
        >
        </SelectCoupon>
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import CButton from '@/views/components/c-button/index.vue'
import SelectCoupon from '@/views/components/selectCoupon/index.vue'
import { signGetDetail, signTemplateSave } from '@/api/O2OMall/manage/signIn'

export default {
    name: 'index',
    components: {
        cardTitleCom,
        CButton,
        SelectCoupon
    },
    data() {
        return {
            content: `勾选后: 表明该会员在第一次满足设定的连续签到次数时，可领取奖励，以后再满足则不能领取:
                                不勾选:表明该会员每次满足设定的连续签到次数时，都可领取:例: 每天1分，每7天20分，每30天100分。则一个月能换 30%+20*4+100=210分`,
            showCoupon: false, // 选择优惠券弹框
            form: {
                activityContent: '',
                activityTitle: '',
                baseRewardIntegral: 0,
                isEnableStatus: false,
                randomRewardIntegralEnd: '',
                randomRewardIntegralStart: '',
                encourageType: '1',

            },
            templateItems: [
                {
                    couponCaseItems: [
                        {
                            couponCaseId: 0,
                            couponCaseName: "",
                            couponCaseNo: "",
                            couponCaseType: "",
                            couponDesc: "",
                            couponQuantity: 0,
                        }
                    ],
                    continuationSignCount: 0,
                    encourageType: '',
                    isGetLimit: '',
                    outsideRewardIntegral: '',
                    randomRewardIntegralEnd: '',
                    randomRewardIntegralStart: '',
                    templateItemId: ''

                }
            ],
            rules: {
                activityTitle: [
                    { required: true, message: '请输入活动标题', trigger: 'blur' }
                ],
                encourageType: [
                    { message: '请选择类型', trigger: 'blur' }
                ]
            },
            ewaiItem: 0 // 选择优惠券的item

        }
    },
    created() {
        this.getSignDetail()
    },
    methods: {
        // 获取签到详情
        getSignDetail() {
            signGetDetail().then(res => {
                if (res.data) {
                    this.form = {
                        activityContent: res.data.activityContent,
                        activityTitle: res.data.activityTitle,
                        baseRewardIntegral: res.data.baseRewardIntegral,
                        isEnableStatus: res.data.isEnableStatus,
                        randomRewardIntegralEnd: res.data.randomRewardIntegralEnd,
                        randomRewardIntegralStart: res.data.randomRewardIntegralStart,
                        encourageType: res.data.encourageType
                        // templateItems: res.data.templateItems,
                    }
                    this.templateItems = []
                    res.data.templateItems.forEach(item => {
                        console.log('----', item)
                        this.templateItems.push(item)
                    })
                    console.log('签到模板详情：', this.templateItems)
                }
            })
        },
        // 删除
        deleteItem(index) {
            console.log('删除当前1：', this.templateItems, index)
            this.templateItems.splice(index, 1)
            console.log('删除当前2：', this.templateItems, index)
        },
        // 添加
        addItem() {
            this.templateItems.push({
                continuationSignCount: '',
                couponCaseItems: [],
                isGetLimit: true,
                encourageType: '1',
                outsideRewardIntegral: '',
                randomRewardIntegralEnd: '',
                randomRewardIntegralStart: '',
                templateItemId: ''

            })
        },
        // 打开选择优惠券弹框
        selectCoupon(index) {
            console.log('送优惠券', index)
            this.ewaiItem = index
            this.showCoupon = true

        },
        // 删除优惠券
        deleteTag(i) {
            console.log('删除：', i)
            this.templateItems[this.ewaiItem].couponCaseItems.splice(i,1)
            console.log('删除后form：', this.form)
        },
        // 选择优惠券
        getCoupons(val) {
            console.log('选择的优惠券：', val, this.templateItems)
            if (!this.templateItems[this.ewaiItem].couponCaseItems) {
                this.templateItems[this.ewaiItem].couponCaseItems = []
            }
            val.forEach(item => {
                this.templateItems[this.ewaiItem].couponCaseItems.push({
                    couponCaseId: item.couponCaseId,
                    couponCaseName: item.couponCaseName
                })
            })
        },
        // 保存
        async saveBtn() {
            this.form.templateItems = this.templateItems
            await signTemplateSave(this.form).then(res => {
                console.log('签到模板：', res)
                this.$message.success('保存成功')
                this.getSignDetail()
            })
        },
        changeInput() {
            var pattern = /^[1-9][0-9]*$/ // 正整数的正则表达式
            // 不符合正整数时
            console.log('---', this.companyBeforeAccount)
            if (!pattern.test(this.companyBeforeAccount)) {
                // input 框绑定的内容为空
                this.companyBeforeAccount = ''
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.container {
    padding: 10px;
    background: #e9e9e9;

    .informBox {
        border: 1px solid #c7ddff;
        background: #ecf3fe;
        padding: 20px;
    }

    .inputW {
        width: 260px;
    }

    .inputW160 {
        width: 160px;
    }

    .btnList {
        padding: 10px;
    }

    .tableBox {
        padding: 10px;
    }


}
</style>
